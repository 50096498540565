<template>
  <div class="col-12 table-responsive">
    <div class="card card-body">
      <table class="table table-hover table-bordered">
        <thead>
          <th>المستخدم</th>
          <th>الكود التسويقي</th>
          <th>عدد المستخدمين</th>
          <th>اجمالي الرصيد</th>
          <th>الرصيد القابل للسحب</th>
        </thead>
        <tbody>
          <tr v-for="user in users" :key="user._id">
            <td>
              <a
                class="text-success"
                @click="$router.push('/users#' + user._id)"
                >{{ user.name }}
                <br />
                {{ user.phone }}</a
              >
            </td>
            <td>
              {{ user.reseller_code }}
            </td>
            <td>
              {{ user.info.users }}
            </td>
            <td>
              {{ user.info.total }}
            </td>
            <td>
              {{ user.info.withdrawal }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      users: [],
    };
  },
  created() {
    var g = this;
    g.loading = true;
    $.post(api + "/admin/users/resellers", {
      jwt: this.user.jwt,
    })
      .then(function (r) {
        g.loading = false;
        g.users = r.response;
      })
      .catch(function () {
        alert("error server or internet");
      });
  },
  methods: {},
};
</script>

<style>
</style>